<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Create Air Destination
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.ServiceName"
                            :error-messages="ServiceNameErrors"
                            label="Service Name"
                            required
                            @input="$v.editedData.ServiceName.$touch()"
                            @blur="$v.editedData.ServiceName.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.ServiceDescription"
                            :error-messages="ServiceDescriptionErrors"
                            label="Service Description"
                            required
                            @input="$v.editedData.ServiceDescription.$touch()"
                            @blur="$v.editedData.ServiceDescription.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success"],
        computed: {
            ServiceNameErrors() {
                const errors = [];
                if (!this.$v.editedData.ServiceName.$dirty) return errors;
                !this.$v.editedData.ServiceName.required &&
                errors.push("Sector Type is required");
                return errors;
            },
            ServiceDescriptionErrors() {
                const errors = [];
                if (!this.$v.editedData.ServiceDescription.$dirty) return errors;
                !this.$v.editedData.ServiceDescription.required &&
                errors.push("Sector Code is required.");
                return errors;
            }
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                province: [],
                show: false,
                updated: false,
                editedData: {
                    ServiceName: "",
                    ServiceDescription: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                ServiceName: {required},
                ServiceDescription: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        ServiceName: this.editedData.ServiceName,
                        ServiceDescription: this.editedData.ServiceDescription
                    };
                    axios.post("Aviation/InsertIntoPassengerServicesAsync", param).then(response => {
                        if (response.status == 200) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Service added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.ServiceName = "";
                this.editedData.ServiceDescription = "";
            }
        }
    };
</script>

<style scoped>
</style>